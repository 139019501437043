import "./Hero.css";
import translation from '../../translation/translation'
import { useLanguage } from '../../context/LanguageContext'
function Hero() {
    const {language} = useLanguage()
    const t = translation[language]
    return (
        <div className="hero">
            <div className="container">
                <div className="hero__container">
                    <h2 className="hero__header">{t.plaintoseeeasytodo}</h2>
                    

                    <div className="hero__btn-wrapper">
                        <button className="hero__btn">
                        <a href="#" className="hero__demo">
                            {t.getafreedemo}
                        </a>
                        </button>
                        <button className="hero__btn"><a href="#" className="hero__pricing">
                            {t.seepricing}
                        </a></button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Hero;
