import "./App.css";
import "./Fonts/fonts.css";
import Header from './components/Header/Header'
import Home from "./pages/home/Home.js";
import { Route } from 'react-router-dom'
import Pricing from "./pages/pricing/Pricing";
import PricingInfo from './components/Pricing/Pricing'
import dataJsons from './data-jsons/data'
import LanguageSelector from "./components/language-selector/LanguageSelector";
import { useState } from "react";
function App() {
    const { pricing_options } = dataJsons
    const { options } = pricing_options
    const [languageBoxState, setLanguageBoxState] = useState(false)
    return (
       <div onClick={e => {
        if (e.target.dataset.selectLanguage !== "select-language") {
            setLanguageBoxState(false);
          }
       }}>
       <LanguageSelector languageBoxState={languageBoxState} setLanguageBoxState={setLanguageBoxState}/>
       <Header />
       <Route exact path='/'>
       <Home/>
       </Route>
       <Route exact path='/products'>
           <Pricing/>
       </Route>
        <Route path={`/pricing/${options[0].link}`}>
               <PricingInfo/>
        </Route>
       </div>
    );
}

export default App;
