import { useState } from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import "./Success.css";
import increase from "../../Images/increase.png";
import decrease from "../../Images/decrease.png";
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";
import dataJsons from '../../data-jsons/data.js'
import { v4 as uuidv4 } from 'uuid'; 
import { useLanguage } from '../../context/LanguageContext'
import translation from '../../translation/translation'
const Success = () => {
    const { language } = useLanguage()
    const t = translation[language]
    const [view, setView] = useState("withSpendIn");
    const { success_page } = dataJsons
    const { success_advantages, success_disadvantages } = success_page
    const handleButtonClick = (newView) => {
        setView(newView);
    };

    useEffect(() => {
        AOS.init({
            duration: 1000,
        });
    }, []);

    return (
        <div className="success">
            <div className="container">
                <div className="success__container">
                    <p className="success__subheader" data-aos="fade-up">
                        {t.increaseproductivity}
                    </p>

                    <h3 className="success__header" data-aos="fade-up">
                    {t.howdoesplain2dowilldifferfrocompetitorsourgeneralapproachbasedonfollowingfactors}
                    </h3>

                    <div className="success__btn-wrapper" data-aos="fade-up">
                        <button
                            className={`success__with ${
                                view === "withSpendIn" ? "active" : ""
                            }`}
                            onClick={() => handleButtonClick("withSpendIn")}
                        >
                            {t.withplain2do}
                        </button>

                        <button
                            className={`success__without ${
                                view === "withoutSpendIn" ? "active" : ""
                            }`}
                            onClick={() => handleButtonClick("withoutSpendIn")}
                        >
                            {t.withoutplain2do}
                        </button>
                    </div>

                    <TransitionGroup data-aos="fade-up">
                        <CSSTransition
                            key={view}
                            timeout={500}
                            classNames="fade"
                        >
                            {view === "withSpendIn" ? (
                                <div className="content">
                                    <div className="content-wrapper">
                                        <h2 className="success__content-header">
                                        {t.ourgeneralapproachbasedonfollowingfactors}
                                        </h2>
                                        <ul className="success__list">
                                            {success_advantages.map(e => 
                                            <li key={uuidv4()} className="success__item-green">
                                              <strong>{e["heading_text"+language.toUpperCase()]}</strong>
                                              <span>{e["text_description"+language.toUpperCase()]}</span>
                                            </li>)}
                                        </ul>
                                    </div>
                                    <div className="success__img-wrapper">
                                        <img
                                            src={increase}
                                            alt="increase with plain2do"
                                            className="success__img"
                                        />
                                    </div>
                                </div>
                            ) : (
                                <div className="content">
                                    <div className="content-wrapper">
                                        <h2 className="success__content-header">
                                           {t.takingtoolongtotidyupadministrativefilesmakesitunproductive}
                                        </h2>
                                        <ul className="success__list">
                                            {success_disadvantages.map(e => 
                                            <li key={uuidv4()} className="success__item-red">
                                                {e["text"+language.toUpperCase()]}
                                            </li>)}
                                        </ul>
                                    </div>
                                    <div className="success__img-wrapper">
                                        <img
                                            src={decrease}
                                            alt="decrease without plain2do"
                                            className="success__img"
                                        />
                                    </div>
                                </div>
                            )}
                        </CSSTransition>
                    </TransitionGroup>
                </div>
            </div>
        </div>
    );
};

export default Success;
