import logo from "../../Images/logo.png";
import "./Footer.css";
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";
import dataJsons from '../../data-jsons/data'
import { v4 as uuidv4 } from 'uuid'
import { useLanguage } from '../../context/LanguageContext'
import translation from '../../translation/translation'
import itparklogo from '../../Images/itpark-logo.png'
import { Link } from 'react-router-dom'
function Footer() {
    useEffect(() => {
        AOS.init({
            duration: 1000,
        });
    }, []);
    const { language } = useLanguage()
    const { footer_icons, footer_column_li, bottom } = dataJsons.footer
    const t = translation[language]
    return (
        <div className="footer">
            <div className="container">
                <div className="footer__container">
                    <div className="footer__upper-wrapper">
                        <div className="footer__upper-left" data-aos="fade-up">
                            <div className="footer__logo-wrapper">
                               <div className="footer__plain2do_wrapper">
                               <img
                                    src={logo}
                                    alt="Logotype of this Webpage Plain2do"
                                    className="footer__logo"
                                />
                                <p className="footer__logo-text">Plain2do</p>
                               </div>
                               <img className="footer_itpark-logo" src={itparklogo} alt="" />
                            </div>

                            <p className="footer__body">
                                {t.datavisualizationandexpensemanagementforyourbusiness}
                            </p>
                            <div className="footer_icons__wrapper">
                                {
                                    footer_icons.map(e => <a className={e.class} key={uuidv4()} href={e.link}><i>{e.icon}</i></a> )
                                }
                            </div>
                        </div>

                        <ul className="footer__list" data-aos="fade-up">
                        {footer_column_li.map(e => 
                            <li key={uuidv4()}  className="footer__item">
                                <ul className="footer__inside-list">
                                    <li className="footer__inside-item">
                                        <p className="footer__inside-header">
                                            {e["heading"+language.toUpperCase()]}
                                        </p>
                                    </li>
                                    {e.options.map(a => 
                                    <li key={uuidv4()} className="footer__inside-item">
                                        <Link
                                            to={a.route}
                                            className="footer__inside-link"
                                        >
                                            {a["option"+language.toUpperCase()]}
                                        </Link>
                                    </li>
                                    )}
                                </ul>
                            </li>
)}
                        </ul>
                    </div>

                    <hr className="footer__line" />

                    <div className="footer__bottom-wrapper">
                        <ul className="footer__bottom-list">
                            {bottom.map((e, index) => 
                            <>
                            <li className="footer__bottom-item">
                                <a href="#" className="footer__bottom-link">
                                    {e["option"+language.toUpperCase()]}
                                </a>
                            </li>
                            {index !== bottom.length-1 ?<li className="footer__bottom-item">
                                <hr className="footer__bottom-line" />
                            </li> : null}
                            </>
)}
                        </ul>

                        <p className="footer__copyright">© Plain2do 2024</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Footer;
