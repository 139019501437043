import PropTypes from 'prop-types';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Pagination } from 'swiper/modules';
import { v4 as uuidv4 } from 'uuid';
import { useLanguage } from '../../context/LanguageContext' 
import { useState } from 'react'
import translation from '../../translation/translation'
import "./Carousel.css"
const Carousel = ({data, isYearly, index}) => {
    const { language } = useLanguage()
    const [optionsLength, setOptionsLength] = useState(5)
    const [indexRender, setIndexRender] = useState()
    const t = translation[language]
    return (
        <Swiper
            style={{
                "--swiper-pagination-color": "purple",
                "--swiper-pagination-bullet-inactive-color": "black",
            }}
            slidesPerView={index}
            spaceBetween={30}
            pagination={{
                dynamicBullets: true,
            }}
            modules={[Pagination]}
        >
             {data.map((e, i) =>
                        <SwiperSlide key={uuidv4()}>
                        <li className="pricing__item">
                            <div className="pricing__header-wrapper">
                                <h3 className="pricing__item-header">{e.title}</h3>
                                {e.state ? <p className="pricing__item-popular">Popular</p> : null}
                            </div>
                            <p className="pricing__item-subheader">
                                {e["description"+language.toUpperCase()]}
                            </p>
                            <div className="pricing__item-wrapper">
                                <h4 style={e.tarif[isYearly ? "yearly" : "monthly"].length >10 ? {fontSize: "32.5px"} : null} className="pricing__item-price">
                                    {e.tarif[isYearly ? "yearly" : "monthly"]}
                                </h4>
                                <p className="pricing__item-per">
                                    /{isYearly ? t.yearly : t.month}
                                </p>
                            </div>
                            <p className="pricing__item-body">
                                {e["recommedtext"+language.toUpperCase()]}
                            </p>
                            <ul className="pricing__item-list">
                                {indexRender == i ? e.recommend_options.slice(0, optionsLength).map(a => <li key={uuidv4()} className={`pricing__item-${a.state ? "pros":"cons"}`}>
                                <strong>{a["option_title"+language.toUpperCase()]}</strong><strong>{a.option_text != ""? ":" : null}</strong>{a.option_text}
                                </li>) : e.recommend_options.slice(0, 5).map(a => <li key={uuidv4()} className={`pricing__item-${a.state ? "pros":"cons"}`}>
                                <strong>{a["option_title"+language.toUpperCase()]}</strong><strong>{a.option_text != ""? ":" : null}</strong>{a.option_text}
                                </li>)
                                }
                                <li className="carousel__pricing_options_seemore">
                                    <p onClick={() => {
                                        setIndexRender(i)
                                        if(optionsLength > 5) {
                                            setOptionsLength(5)
                                        }
                                        else {
                                            setOptionsLength(e.recommend_options.length)}
                                    }} className="carousel__seemore_option_text">{optionsLength > 5 && indexRender == i ? "See less" : "See more"}</p>
                                </li>
                            </ul>
                            <button className="pricing__item-btn">
                                {e.btn_text}
                            </button>
                        </li>
                        </SwiperSlide>
)}
        </Swiper>
    );
};

Carousel.propTypes = {
    data: PropTypes.array.isRequired, // Assuming data is an array, adjust if needed
    isYearly: PropTypes.bool.isRequired, // Expecting isYearly to be a boolean
    index: PropTypes.number.isRequired
};

export { Carousel };
