import dataJsons from '../../data-jsons/data'
import './Pricing.scss'
import { useEffect } from 'react'
import { v4 as uuidv4 } from 'uuid'; //key={uuidv4()}
import { Link } from 'react-router-dom'
import AOS from "aos";
import "aos/dist/aos.css";
import { useLanguage } from '../../context/LanguageContext'
const Pricing = () => {
    const { pricing_options } = dataJsons
    const { options } = pricing_options
    const { language } = useLanguage()
    useEffect(() => {
        AOS.init({
            duration: 1000,
        });
    }, [])
    return (
        <div className="pricing_options">
            <div className="pricing_options__container">
            {options.map(e => {
                return <div data-aos="fade-up" key={uuidv4()} className="pricing_options__card">
                     <Link className="pricing_options__card_link"  to={`/pricing/${e.link}`}>
                    <img src={e.pricing_img} alt="" />
                    <div className="pricing_options__card_box">
                    <h2 className="pricing_options__heading_text">{e["pricing_text"+language.toUpperCase()]}</h2>
                    <p className="pricing_options__card_text">{e["text_body"+language.toUpperCase()]}</p>
                    </div>
                </Link>
                </div>
            })}
            </div>
        </div>
    )
}

export default Pricing