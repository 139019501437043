export default {
    en: {
        plain2Doworkforce:"Plain2Do Workforce",
        planexecuteachieve: "Plan Execute Achieve",
        workforceherotext:"Plain2Do Workforce Module is a Human Resource Management (HRM) tool designed to streamline employee management for both small and medium enterprises (SMEs) and larger corporations. This module offers a comprehensive suite of features aimed at enhancing HR processes and improving overall workforce efficiency.",
        keyfeatures: "Key Features",
        benefitsforyourcompany: "Benefits for Your Company",
        readytogetstarted:"Ready to Get Started?",
        chooseaplanthatsuitsyourbusinessneeds: "Choose a plan that suits your business needs",
        monthly:"Monthly",
        yearly: "Yearly",
        month: "month",
        priceondemand: "Price on demand",
        login: "Login",
        getdemo: "Get Demo",
        plaintoseeeasytodo: "Plain to see - Easy to Do",
        getafreedemo: "Get a Free Demo",
        seepricing: "See Pricing",
        howitworks: "HOW IT WORKS",
        feweasystepsanddone: "Few Easy Steps and Done",
        injustfeweasystepyouareallsettomanageyourbusinessfinancesmanageallexpenseswithspendinallinoneplace:"In just few easy step, you are all set to manage your business finances. Manage all expenses with Spend.In all in one place.",
        whyuseplain2do: "WHY USE PLAIN2DO",
        easysimpleaffordable: "Easy, Simple, Affordable",
        benefit_description: "Our mission at Plain2Do is to empower businesses and individuals by providing a user-friendly and efficient SaaS platform for workforce, procurement, property and other process management. We aim to simplify task management, enhance collaboration, and optimize productivity, enabling our users to achieve their goals with ease and clarity. Through our intuitive software and innovative features, we strive to streamline workflows, eliminate complexities, and unlock the full potential of every process.",
        increaseproductivity: "INCREASE PRODUCTIVITY",
        howdoesplain2dowilldifferfrocompetitorsourgeneralapproachbasedonfollowingfactors: "How does Plain2do will differ from competitors? Our general approach, based on following factors:",
        withplain2do: "With Plain2do",
        withoutplain2do: "Without Plain2do",
        ourgeneralapproachbasedonfollowingfactors: "Our general approach, based on following factors:",
        takingtoolongtotidyupadministrativefilesmakesitunproductive: "Taking too long to tidy up administrative files makes it unproductive",
        whattheysay: "WHAT THEY SAY",
        ouruserkindwords: "Our User Kind Words",
        herearesometestimonialsfromouruserafterusingplain2dotomanagetheirbusinessexpenses: "Here are some testimonials from our user after using Plain2do to manage their business expenses.",
        datavisualizationandexpensemanagementforyourbusiness: "Data visualization, and expense management for your business."
    },
    tr: {
        plain2Doworkforce: "Plain2Do işgücü",
        planexecuteachieve: "Planla, Uygula, Başar",
        workforceherotext: "Plain2Do Workforce, küçük ve orta ölçekli işletmeler (KOBİ'ler) ile büyük şirketler için İK yönetimini kolaylaştırmak amacıyla tasarlanmış SaaS İnsan Kaynakları Yönetimi (IKY) aracıdır. Bu modül, İK süreçlerini geliştirmeye ve genel iş gücü verimliliğini artırmaya yönelik kapsamlı bir özellik seti sunmaktadır.",
        keyfeatures: "Ana Özellikler",
        benefitsforyourcompany: "Şirketiniz İçin Faydaları",
        readytogetstarted: "Başlamaya hazır mısın?",
        chooseaplanthatsuitsyourbusinessneeds: "İş ihtiyaçlarınıza uygun bir plan seçin",
        monthly: "Aylık",
        yearly: "Yıllık",
        month: "ay",
        priceondemand: "Talep üzerine fiyat",
        login : "Oturum aç",
        getdemo: "Demo alın",
        plaintoseeeasytodo: "Görülmesi kolay - Yapması kolay",
        getafreedemo: "Ücretsiz Demo Alın",
        seepricing: "Fiyatlandırmaya Bakın",
        howitworks: "Nasıl çalışır",
        feweasystepsanddone: "Birkaç Kolay Adım ve Bitti",
        injustfeweasystepyouareallsettomanageyourbusinessfinancesmanageallexpenseswithspendinallinoneplace: "Sadece birkaç kolay adımda, hepiniz işletme finansmanınızı yönetmeye hazırsınız. Tüm masrafları ile yönetin Spend.In hepsi tek bir yerde.",
        whyuseplain2do: "NEDEN PLAİN2DO kullanmalı",
        easysimpleaffordable: "Kolay, Basit, Uygun Fiyatlı",
        benefit_description: "Plain2do'daki misyonumuz, işgücü, tedarik, mülk ve diğer süreç yönetimi için kullanıcı dostu ve verimli bir SaaS platformu sağlayarak işletmeleri ve bireyleri güçlendirmektir. Kullanıcılarımızın hedeflerine kolaylıkla ve net bir şekilde ulaşmalarını sağlayarak görev yönetimini basitleştirmeyi, işbirliğini geliştirmeyi ve üretkenliği optimize etmeyi amaçlıyoruz. Sezgisel yazılımımız ve yenilikçi özelliklerimiz sayesinde iş akışlarını düzene sokmaya, karmaşıklıkları ortadan kaldırmaya ve her sürecin tüm potansiyelini ortaya çıkarmaya çalışıyoruz.",
        increaseproductivity: "VERİMLİLİĞİ ARTIRIN",
        howdoesplain2dowilldifferfrocompetitorsourgeneralapproachbasedonfollowingfactors: "Plain2do rakiplerinden nasıl farklı olacak? Aşağıdaki faktörlere dayanan genel yaklaşımımız:",
        withplain2do: "Plain2do ile",
        withoutplain2do: "Plain2do olmadan",
        ourgeneralapproachbasedonfollowingfactors: "Aşağıdaki faktörlere dayanan genel yaklaşımımız:",
        takingtoolongtotidyupadministrativefilesmakesitunproductive: "Yönetimsel dosyaları toplamak çok uzun sürerse verimsiz hale gelir",
        whattheysay: "NE DERLER",
        ouruserkindwords: "Kullanıcı Nazik Sözlerimiz",
        herearesometestimonialsfromouruserafterusingplain2dotomanagetheirbusinessexpenses: "İşte işletme giderlerini yönetmek için Plain2do'yu kullandıktan sonra kullanıcımızdan bazı referanslar.",
        datavisualizationandexpensemanagementforyourbusiness: "İşletmeniz için veri görselleştirme ve gider yönetimi."
    },
    ru:{
        plain2Doworkforce: "Рабочая сила Plain2Do",
        planexecuteachieve: "Планируй, Применяй, Добивайся успеха!",
        workforceherotext: "Plain2Do Workforce — это инструмент управления человеческими ресурсами (HRM) в формате SaaS, разработанный для упрощения управления сотрудниками как для малых и средних предприятий (МСП), так и для крупных корпораций. Этот модуль предлагает комплексный набор функций, направленных на улучшение HR-процессов и повышение общей эффективности рабочей силы.Ключевые функции",
        keyfeatures: "Ключевые функции",
        benefitsforyourcompany: "Преимущества для Вашей компании",
        readytogetstarted: "Готовы приступить к работе?",
        chooseaplanthatsuitsyourbusinessneeds:"Выберите тарифный план, соответствующий потребностям вашего бизнеса",
        monthly: "Ежемесячно",
        yearly: "Годовой",
        month: "месяц",
        priceondemand: "Цена по запросу",
        login: "Войти",
        getdemo: "Получить демо-версию",
        plaintoseeeasytodo: "Просто увидеть - легко сделать",
        getafreedemo: "Получить бесплатную демо-версию",
        seepricing: "Смотреть цены",
        howitworks: "как это работает",
        feweasystepsanddone: "Несколько простых шагов - и готово",
        injustfeweasystepyouareallsettomanageyourbusinessfinancesmanageallexpenseswithspendinallinoneplace: "Всего за несколько простых шагов вы сможете управлять финансами своего бизнеса. Управляйте всеми расходами с помощью Spend.Все это в одном месте.",
        whyuseplain2do: "ЗАЧЕМ ИСПОЛЬЗОВАТЬ PLAIN2DO",
        easysimpleaffordable: "Легкий, простой, доступный по цене",
        benefit_description: "Наша миссия в Plain2Do заключается в расширении возможностей предприятий и частных лиц путем предоставления удобной и эффективной SaaS-платформы для управления персоналом, закупками, имуществом и другими процессами. Мы стремимся упростить управление задачами, улучшить сотрудничество и оптимизировать производительность, позволяя нашим пользователям достигать своих целей с легкостью и ясностью. Благодаря нашему интуитивно понятному программному обеспечению и инновационным функциям мы стремимся оптимизировать рабочие процессы, устранить сложности и полностью раскрыть потенциал каждого процесса.",
        increaseproductivity:"ПОВЫШЕНИЕ ПРОИЗВОДИТЕЛЬНОСТИ ТРУДА",
        howdoesplain2dowilldifferfrocompetitorsourgeneralapproachbasedonfollowingfactors: "Чем Plain2do будет отличаться от конкурентов? Наш общий подход основан на следующих факторах:",
        withplain2do: "С Plain2do",
        withoutplain2do: "Без Plain2do",
        ourgeneralapproachbasedonfollowingfactors:"Наш общий подход, основанный на следующих факторах:",
        takingtoolongtotidyupadministrativefilesmakesitunproductive: "Слишком долгое приведение в порядок административных файлов делает это непродуктивным",
        whattheysay: "ЧТО ОНИ ГОВОРЯТ",
        ouruserkindwords: "Добрые слова нашего пользователя",
        herearesometestimonialsfromouruserafterusingplain2dotomanagetheirbusinessexpenses: "Вот несколько отзывов от наших пользователей после использования Plain2do для управления своими бизнес-расходами.",
        datavisualizationandexpensemanagementforyourbusiness: "Визуализация данных и управление расходами для вашего бизнеса."
    }
}