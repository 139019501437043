import "./Benefit.css";
import AOS from "aos";
import "aos/dist/aos.css";
// import b1 from "../../Images/b-1.png";
// import b2 from "../../Images/b-2.png";
// import b3 from "../../Images/b-3.png";
import { useEffect } from "react";
import translation from '../../translation/translation'
import { useLanguage } from '../../context/LanguageContext'
function Benefit() {
    useEffect(() => {
        AOS.init({
            duration: 1000,
        });
    }, []);
    const { language } = useLanguage()
    const t = translation[language]
    return (
        <div className="benefit">
            <div className="container">
                <div className="benefit__container">
                    <h4 className="benefit__subheader">{t.whyuseplain2do}</h4>

                    <div className="benefit__wrapper" data-aos="fade-up">
                        <h3 className="benefit__header">
                            {t.easysimpleaffordable}
                        </h3>

                        <p className="benefit__body">
                            {t.benefit_description}
                        </p>
                    </div>

                    {/* <ul className="benefit__list benefits_options" data-aos="fade-up">
                        <li className="benefit__item benefit_option_li">
                            <img
                                src={b1}
                                alt="benefit of Plain2do"
                                className="benefit__image"
                            />

                            <p className="benefit__item-header">
                                Automatic Invoice Payment
                            </p>

                            <p className="benefit__item-body">
                                No need to pay manually, we provide automatic
                                invoice payment service! Set a payment schedule
                                and you are done, it is that easy!
                            </p>
                        </li>

                        <li className="benefit__item">
                            <img
                                src={b2}
                                alt="benefit of Plain2do"
                                className="benefit__image"
                            />

                            <p className="benefit__item-header">
                                Clear payment history
                            </p>

                            <p className="benefit__item-body">
                                Still writing manual expenses? Our platform
                                breaks down every expense you log down to the
                                millisecond!
                            </p>
                        </li>

                        <li className="benefit__item">
                            <img
                                src={b3}
                                alt="benefit of Plain2do"
                                className="benefit__image"
                            />

                            <p className="benefit__item-header">
                                Use of multi-card payments
                            </p>

                            <p className="benefit__item-body">
                                Have more than 1 bank account or credit/debit
                                card? Our platform is already integrated with
                                many banks around the world, for easier
                                payments!
                            </p>
                        </li>
                    </ul> */}
                </div>
            </div>
        </div>
    );
}

export default Benefit;
