import ReactDOM from "react-dom/client";
import App from "./App";
import { BrowserRouter } from 'react-router-dom'
import { LanguageProvider } from './context/LanguageContext'; // Adjust path as necessary
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
<BrowserRouter>
<LanguageProvider>
<App />
</LanguageProvider>
</BrowserRouter>
);
