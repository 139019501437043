import { useState } from "react";
import logo from "../../Images/logo.png";
import "./Header.scss";
import { NavLink  } from 'react-router-dom'
import datajsons from '../../data-jsons/data'
import { useLanguage } from '../../context/LanguageContext'
import { v4 as uuidv4 } from 'uuid';
import translation from '../../translation/translation'
import { Link } from "react-router-dom/cjs/react-router-dom.min";
// import { Link } from "react-router-dom/cjs/react-router-dom.min";
function Header() {
    const { header_navbar } = datajsons
    const [menuOpen, setMenuOpen] = useState(false);
    // const {pathname} = useLocation()
    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };
    const userLanguage = navigator.language 
    console.log(userLanguage);
    const closeMenu = () => {
        setMenuOpen(false);
    };
const { language } = useLanguage()
const t = translation[language]
     return (
        <header className="header">
            <div className="container">
                <div className="header__container">
                    <NavLink  to="#" className="header__logo-wrapper">
                        <img
                            src={logo}
                            alt="Logotype of this webpage"
                            className="header__logo"
                        />
                        <p className="header__logo-text">Plain2do</p>
                    </NavLink >

                    <ul className="header__list">
                        {header_navbar.map(e => 
                    <li key={uuidv4()} className="header__item header__dropdown">
                                <NavLink activeClassName="active__link" exact className="header__item-NavLink " to={e.route}>{e["nav_li"+language.toUpperCase()]}</NavLink >
                    </li>)}
                        {/* <li className="header__item header__dropdown">
                            <NavLink activeClassName="active__link"  to="/products" className="header__item-NavLink ">
                                Products
                            </NavLink >
                            <div className="header__dropdown-content">
                                <NavLink activeClassName="active__link"
to="" className="header__dropdown-NavLink "
                                    href="#product1"
                                >
                                    Product 1
                                </NavLink >
                                <NavLink activeClassName="active__link"
to="" className="header__dropdown-NavLink "
                                    href="#product2"
                                >
                                    Product 2
                                </NavLink >
                                <NavLink activeClassName="active__link"
to="" className="header__dropdown-NavLink "
                                    href="#product3"
                                >
                                    Product 3
                                </NavLink >
                            </div>
                        </li> */}
                        {/* <li className="header__item header__dropdown">
                            <NavLink  activeClassName="active__link" to="/company" className="header__item-NavLink ">
                                Company
                            </NavLink >
                            <div className="header__dropdown-content">
                                <NavLink activeClassName="active__link"
to= "/career"className="header__dropdown-NavLink "
                                >
                                    Career
                                </NavLink >
                                <NavLink activeClassName="active__link"
to='/agency' className="header__dropdown-NavLink "
                                    href="#agency"
                                >
                                    Agency
                                </NavLink >
                            </div>
                        </li> */}
                    </ul>

                    <div className="header__btn-wrapper">
                        <Link to="/login" className="header__login">
                            {t.login}
                        </Link >
                        <NavLink  to="get-demo" className="header__demo">
                            {t.getdemo}
                        </NavLink >
                    </div>

                    <button className="header__burger" onClick={toggleMenu}>
                        <span className="header__burger-line"></span>
                        <span className="header__burger-line"></span>
                        <span className="header__burger-line"></span>
                    </button>

                    {menuOpen && (
                        <div
                            className="header__overlay"
                            onClick={closeMenu}
                        ></div>
                    )}

                    <div
                        className={`header__mobile-menu ${
                            menuOpen ? "header__mobile-menu--open" : ""
                        }`}
                    >
                        <ul className="header__mobile-list">
                        {header_navbar.map(e => 
                        <li key={uuidv4()} className="header__mobile-item">
                                <NavLink activeClassName="active_mobile_link" exact to={e.route}>{e["nav_li"+language.toUpperCase()]}</NavLink >
                        </li>)}
                        </ul>
                        <div className="header__mobile-btn-wrapper">
                            <Link to = "/login" className="header__mobile-login">
                                {t.login}                                
                            </Link>
                            <NavLink  to="/get-demo" className="header__mobile-demo">
                                {t.getdemo}
                            </NavLink >
                        </div>
                    </div>
                </div>
            </div>
        </header>
    );
}

export default Header;
