import { useState } from 'react';
import russianFlag from "../../Images/russian.png";
import britishFlag from "../../Images/england.png";
import turkishFlag from "../../Images/turkish.png";
import { useLanguage } from '../../context/LanguageContext';
import PropTypes from 'prop-types'; // Import PropTypes
import './index.css';

const LanguageSelector = ({ languageBoxState, setLanguageBoxState }) => {
  const language2 = useLanguage()?.language || "en";
  const { switchLanguage } = useLanguage();
  
  const [languages, setLanguages] = useState([
    { language: "en", src: britishFlag },
    { language: "ru", src: russianFlag },
    { language: "tr", src: turkishFlag },
  ]);

  // Move the currently selected language to the front of the array
  languages.forEach(e => {
    if (e.language === language2) {
      languages.unshift(e);
      languages.splice(languages.lastIndexOf(e), 1);
    }
  });

  return (
    <div
      data-select-language="select-language"
      style={languageBoxState ? { right: 0 } : { right: "-170px" }}
      onClick={() => {
        setLanguageBoxState(!languageBoxState);
      }}
      className="registration-demo-select-language"
    >
      {languages.map((e, index) => (
        <div key={index}
          data-select-language="select-language"
          onClick={() => {
            setLanguages(prevLanguages => {
              // Move the selected language to the front
              const newLanguages = [...prevLanguages];
              newLanguages.unshift(e);
              newLanguages.splice(newLanguages.lastIndexOf(e), 1);
              return newLanguages;
            });
            switchLanguage(e.language);
          }}
          className="registration-demo-language-option-box"
        >
          <img data-select-language="select-language" src={e.src} alt={`${e.language} flag`} />
        </div>
      ))}
    </div>
  );
};

// Add prop types for LanguageSelector
LanguageSelector.propTypes = {
  languageBoxState: PropTypes.bool.isRequired,
  setLanguageBoxState: PropTypes.func.isRequired,
};

export default LanguageSelector;
