import test1 from "../../Images/test.png";
// import left from "../../Images/arrow-left.svg";
// import right from "../../Images/arrow-right.svg";
import "./Testimonial.css";
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";
import { Swiper, SwiperSlide } from 'swiper/react';
import { v4 as uuidv4 } from 'uuid'; 
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import { useLanguage } from '../../context/LanguageContext'
import translation from '../../translation/translation'
// import required modules
import { Pagination } from 'swiper/modules';
import dataJsons from '../../data-jsons/data'
function Testimonial() {
    const { language } = useLanguage()
    const t = translation[language]
    useEffect(() => {
        AOS.init({
            duration: 1000,
        });
    }, []);
    const { user_comments } = dataJsons
    return (
        <div className="test">
            <div className="container">
                <div className="test__container">
                    <p className="test__subheader" data-aos="fade-up">
                        {t.whattheysay}
                    </p>

                    <h3 className="test__header" data-aos="fade-up">
                        {t.ouruserkindwords}
                    </h3>

                    <p className="test__body" data-aos="fade-up">
                        {t.herearesometestimonialsfromouruserafterusingplain2dotomanagetheirbusinessexpenses}
                    </p>

                    <ul className="test__list" data-aos="fade-up">
                      <Swiper style={{
  "--swiper-pagination-color": "purple",
  "--swiper-pagination-bullet-inactive-color": "#fff",
//   "--swiper-pagination-bullet-inactive-opacity": "1",
//   "--swiper-pagination-bullet-size": "16px",
//   "--swiper-pagination-bullet-horizontal-gap": "6px"
}}
        pagination={{
          dynamicBullets: true,
        }}
        modules={[Pagination]}
        className="mySwiper"
      >
          {user_comments.map((e) =>  
<SwiperSlide key={uuidv4()}><li className="test__item">
                            <p className="test__item-title">
                                {e["title"+language.toUpperCase()]}
                            </p>

                            <p className="test__item-body">
                                {e["body"+language.toUpperCase()]}
                            </p>

                            <hr className="test__line" />

                            <div className="test__item-wrapper">
                                <img
                                    src={test1}
                                    alt="Testimonial of plain2do"
                                    className="test__item-img"
                                />

                                <div className="test__name-wrapper">
                                    <p className="test__item-name">
                                       {e["username"+language.toUpperCase()]}
                                    </p>

                                    <p className="test__item-job">
                                        {e["job"+language.toUpperCase()]}
                                    </p>
                                </div>
                            </div>
                        </li>
                      </SwiperSlide>
                                )}
      </Swiper>
                    </ul>

                    {/* <div className="test__btn-wrapper">
                        <button className="test__left">
                            <img
                                src={left}
                                alt="arrof for left"
                                className="test__left-img"
                            />
                        </button>

                        <button className="test__right">
                            <img
                                src={right}
                                alt="arrof for right"
                                className="test__right-img"
                            />
                        </button>
                    </div> */}
                </div>
            </div>
        </div>
    );
}

export default Testimonial;
