import "./Pricing.css";
import { useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
// import { MdKeyboardBackspace } from "react-icons/md";
// import { Link } from 'react-router-dom'
import dataJsons from '../../data-jsons/data.js'
import "aos/dist/aos.css";
import { useEffect } from "react";
import { v4 as uuidv4 } from 'uuid'; 
import { useLanguage } from '../../context/LanguageContext'
import translation from '../../translation/translation'
// Import Swiper styles

import { Carousel } from "../Carousel/Carousel";

function PricingInfo() {
    const { language } = useLanguage()
    const { pricing_tarifs } = dataJsons
    const [isYearly, setIsYearly] = useState(false);
    const handleToggle = () => {
        setIsYearly(!isYearly);
    };

    useEffect(() => {
        AOS.init({
            duration: 1000,
        });
    }, []);
    const t = translation[language]

    return (
        <div className="pricing">
            <div className="container">
            {/* <Link to='/pricing'>
            <div className="pricing__back_btn_box" data-aos="fade-up"><span className="pricing__btn_back"><MdKeyboardBackspace/></span>Back</div>
        </Link> */}
                <div className="pricing__container">
                    <h2 className="pricing__title" data-aos="fade-up">{t.plain2Doworkforce}</h2>
                    <h3 data-aos="fade-up" className="pricing__header pricing__header_slogan">{t.planexecuteachieve}</h3>
                    <p data-aos="fade-up" className="pricing_hero_text_description">{t.workforceherotext}</p>
                    <h3 data-aos="fade-up" className="pricing__header">{t.keyfeatures}</h3>
                    {dataJsons.pricing_options.options[0].workforce_features.map((e,i) => 
                    <div data-aos="fade-up" className="pricing_card_feature_container" style={(i+1)%2==0?{justifyContent: "flex-end"}: {justifyContent: "flex-start"}} key={uuidv4()}>
                        <div className="pricing_card_feature_box">
                        <i className="pricing__keyfeature_icons">{e.img_icon}</i>
                        <div className="pricing_feature_text_box">
                        <h3 className="pricing_feature_heading">{e["title"+language.toUpperCase()]}</h3>
                        <p className="pricing_feature_description_text">{e["text"+language.toUpperCase()]}</p>
                        </div>
                        </div>
                    </div>    
                    )}
<h3 data-aos="fade-up" className="pricing__header">{t.benefitsforyourcompany}</h3>
{dataJsons.pricing_options.options[0].benefits.map((e,i) => 
                    <div data-aos="fade-up" className="pricing_card_feature_container" style={(i)%2==0?{justifyContent: "flex-end"}: {justifyContent: "flex-start"}} key={uuidv4()}>
                        <div className="pricing_card_feature_box">
                        <i className="pricing__keyfeature_icons">{e.img_icon}</i>
                        <div className="pricing_feature_text_box">
                        <h3 className="pricing_feature_heading">{e["title"+language.toUpperCase()]}</h3>
                        <p className="pricing_feature_description_text">{e["text"+language.toUpperCase()]}</p>
                        </div>
                        </div>
                    </div>    
                    )}
                    <h3 className="pricing__header" data-aos="fade-up">
                        {t.readytogetstarted}
                    </h3>
                    <p className="pricing__subheader" data-aos="fade-up">
                        {t.chooseaplanthatsuitsyourbusinessneeds}
                    </p>
                    <div className="pricing__wrapper" data-aos="fade-up">
                        <p className="pricing__text">{t.monthly}</p>
                        <div className="toggle-switch">
                            <input
                                type="checkbox"
                                id="pricing"
                                checked={isYearly}
                                onChange={handleToggle}
                            />
                            <label htmlFor="pricing" className="switch"></label>
                        </div>
                        <p className="pricing__text">{t.yearly}</p>
                        <span className="pricing__dec">Save 65%</span>
                    </div>

                    <ul className="pricing__computer-carousel" data-aos="fade-up">
                    <Carousel index={3} isYearly={isYearly} data={pricing_tarifs} />
                    </ul>
                    <ul className="pricing__tablet-carousel" data-aos="fade-up">
                    <Carousel index={2} isYearly={isYearly} data={pricing_tarifs} />
                    </ul>
                    <ul className="pricing__phone-carousel" data-aos="fade-up">
                    <Carousel index={1} isYearly={isYearly} data={pricing_tarifs} />
                    </ul>
                </div>
            </div>
        </div>
    );
}

export default PricingInfo;
