import "./How.css";
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";
import { useLanguage } from '../../context/LanguageContext'
import translation from '../../translation/translation'
import datajsons from '../../data-jsons/data'
import { v4 as uuidv4 } from 'uuid';
function How() {
    const { language } = useLanguage()
    const t = translation[language]
    const { how_steps } = datajsons
    useEffect(() => {
        AOS.init({
            duration: 1000,
        });
    }, []);
    return (
        <div className="how">
            <div className="container">
                <div className="how__container">
                    <p className="how__subheader">{t.howitworks.toUpperCase()}</p>

                    <h3 className="how__header" data-aos="fade-up">
                        {t.feweasystepsanddone}
                    </h3>

                    <p className="how__body" data-aos="fade-up">
                        {t.injustfeweasystepyouareallsettomanageyourbusinessfinancesmanageallexpenseswithspendinallinoneplace}
                    </p>

                    <ul className="how__list" data-aos="fade-up">
                        {how_steps.map(e => 
                        <li key={uuidv4()} className="how__item">
                            <img
                                src={e.url}
                                alt="Register in Plain2do"
                                className="how__img"
                            />

                            <p className="how__item-body">
                                {e["text"+language.toUpperCase()]}
                            </p>
                        </li>)}
                    </ul>

                    <div className="how__btn-wrapper" data-aos="fade-up">
                        <a href="#" className="how__demo">
                            {t.getafreedemo}
                        </a>
                        <a href="#" className="how__pricing">
                            {t.seepricing}
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default How;
